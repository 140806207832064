import $ from 'jquery';

;(() => {

  const $window = $(window);
  const hasWindow = $window && $window.length;
  const $document = $(document);
  const hasDocument = $document && $document.length;
  const $singleClient = $('.single-client');
  const hasSingleClient = $singleClient && $singleClient.length;
  const $clientImageGallery = $singleClient.find('.client-image-gallery');
  const hasClientImageGallery = $clientImageGallery && $clientImageGallery.length;
  const $clientImageGalleryMasonry = $singleClient.find('.client-image-gallery-masonry');
  const hasClientImageGalleryMasonry = $clientImageGalleryMasonry &&
    $clientImageGalleryMasonry.length;
  const $clientImageItem = $singleClient.find('.client-image-item');
  const hasClientImageItem = $clientImageItem && $clientImageItem.length;

  if (
    hasWindow &&
    hasDocument &&
    hasSingleClient &&
    hasClientImageGallery &&
    hasClientImageItem &&
    hasClientImageGalleryMasonry
  ) {
    const clientImageItemAmount = $clientImageItem.length / 2;
    const $clientImageSlideshow = $singleClient.find('.client-image-slideshow');
    const hasClientImageSlideshow = $clientImageSlideshow && $clientImageSlideshow.length;
    const $imageSlideshowBox = $clientImageSlideshow.find('.image-slideshow-box');
    const hasImageSlideshowBox = $imageSlideshowBox && $imageSlideshowBox.length;
    const $imageSlideshowNavigation = $clientImageSlideshow.find('.image-slideshow-navigation');
    const $slideshowNavPrev = $imageSlideshowNavigation.find('.slideshow-nav-prev');
    const hasSlideshowNavPrev = $slideshowNavPrev && $slideshowNavPrev.length;
    const $slideshowNavNext = $imageSlideshowNavigation.find('.slideshow-nav-next');
    const hasSlideshowNavNext = $slideshowNavNext && $slideshowNavNext.length;
    const $slideshowNavigationInfoCurrentImage = $clientImageSlideshow.find(
      '.slideshow-navigation-info .current-image',
    );
    const hasSlideshowNavigationInfoCurrentImage = $slideshowNavigationInfoCurrentImage &&
      $slideshowNavigationInfoCurrentImage.length;
    const $closeClientSlideshow = $clientImageSlideshow.find('.close-client-slideshow');
    const hasCloseClientSlideshow = $closeClientSlideshow && $closeClientSlideshow.length;
    const $singleClientScrollAnimated = $singleClient.find('[scroll-animated]');
    const hasSingleClientScrollAnimated = $singleClientScrollAnimated &&
      $singleClientScrollAnimated.length;
    let currentSlideshowPosition = 0;
    let windowHeight = 0;

    const updateSlideshow = () => {
      if (hasImageSlideshowBox) {
        if (currentSlideshowPosition < 0) {
          currentSlideshowPosition = clientImageItemAmount - 1;
        }

        if (currentSlideshowPosition >= clientImageItemAmount) {
          currentSlideshowPosition = 0;
        }

        const $currentImageSlideshowBox = $imageSlideshowBox.eq(currentSlideshowPosition);

        $imageSlideshowBox.removeClass('visible');
        $currentImageSlideshowBox.addClass('visible');

        if (hasSlideshowNavigationInfoCurrentImage) {
          $slideshowNavigationInfoCurrentImage.text(currentSlideshowPosition + 1);
        }
      }
    }

    const updateSingleClientScrollAnimated = scrollTop => {
      if (!$singleClientScrollAnimated || !$singleClientScrollAnimated.length) {
        return;
      }

      $singleClientScrollAnimated.each(index => {
        const $currentSingleClientScrollAnimated = $singleClientScrollAnimated.eq(index);
        const currentSingleClientScrollAnimatedOffsetTop = $currentSingleClientScrollAnimated
          .offset().top;
        const currentSingleClientScrollAnimatedHeight = $currentSingleClientScrollAnimated
          .innerHeight();
        let currentSingleClientScrollAnimatedFixHeight = parseInt(
          $currentSingleClientScrollAnimated.attr('scroll-animated'),
          10,
        );

        if (currentSingleClientScrollAnimatedFixHeight < 0) {
          return;
        }

        let visiblePosition = currentSingleClientScrollAnimatedOffsetTop;
        visiblePosition = visiblePosition + (
          currentSingleClientScrollAnimatedHeight * (
            currentSingleClientScrollAnimatedFixHeight / 100
          )
        );
        visiblePosition = visiblePosition - windowHeight;

        if (scrollTop >= visiblePosition) {
          $currentSingleClientScrollAnimated.addClass('visible');
        } else {
          $currentSingleClientScrollAnimated.removeClass('visible');
        }
      });
    }

    const setupSlideshow = () => {
      updateSlideshow();

      if (hasSlideshowNavPrev) {
        $slideshowNavPrev.click(() => {
          currentSlideshowPosition = currentSlideshowPosition - 1;
          updateSlideshow();
        });
      }

      if (hasSlideshowNavNext) {
        $slideshowNavNext.click(() => {
          currentSlideshowPosition = currentSlideshowPosition + 1;
          updateSlideshow();
        });
      }
    }

    const handleScrollTop = () => {
      $('html, body').scrollTop(0);
    }

    const showSlideshow = () => {
      $clientImageSlideshow.addClass('show');
      $clientImageGallery.addClass('hide');
      $clientImageGalleryMasonry.addClass('hide');
      handleScrollTop();
    }

    const hideSlideshow = () => {
      $clientImageSlideshow.removeClass('show');
      $clientImageGallery.removeClass('hide');
      $clientImageGalleryMasonry.removeClass('hide');
      handleScrollTop();
    }

    const onClickClientImageItem = event => {
      const $currentTarget = $(event.currentTarget);
      const imageIndex = parseInt($currentTarget.attr('image-index'), 10);

      if (Number.isInteger(imageIndex)) {
        currentSlideshowPosition = imageIndex;
        updateSlideshow();
        showSlideshow();
      }
    }

    const onScrollWindow = () => {
      if (hasSingleClientScrollAnimated) {
        const scrollTop = $window.scrollTop();

        updateSingleClientScrollAnimated(scrollTop);
      }
    }

    const onResizeWindow = () => {
      windowHeight = $window.innerHeight();
      onScrollWindow();
    }

    const onDocumentoReady = () => {
      onResizeWindow();

      if (hasClientImageSlideshow) {
        setupSlideshow();
      }

      $clientImageItem.click(onClickClientImageItem);

      if (hasCloseClientSlideshow) {
        $closeClientSlideshow.click(hideSlideshow);
      }

      $window.resize(onResizeWindow);
      $window.scroll(onScrollWindow);
    }

    $document.ready(onDocumentoReady);
  }

})();

import $ from 'jquery';

;(() => {

  const $window = $(window);
  const hasWindow = $window && $window.length;
  const $document = $(document);
  const hasDocument = $document && $document.length;
  const $privateClientsWeddingsTemplate = $('.page-template-private-clients-weddings');
  const hasPrivateClientsWeddingsTemplate = $privateClientsWeddingsTemplate &&
    $privateClientsWeddingsTemplate.length;

  if (hasWindow && hasDocument && hasPrivateClientsWeddingsTemplate) {
    const $privateWeddingsScrollAnimated = $privateClientsWeddingsTemplate.find(
      '[scroll-animated]',
    );
    const hasPrivateWeddingsScrollAnimated = $privateWeddingsScrollAnimated &&
      $privateWeddingsScrollAnimated.length;
    const $privateWeddingsCarousel = $privateClientsWeddingsTemplate.find('.private-weddings-carousel');
    const $privateWeddingsCarouselImages = $privateWeddingsCarousel.find('.private-weddings-carousel-images');
    const $privateWeddingsCarouselItemImage = $privateWeddingsCarouselImages.find('.private-weddings-carousel-item-image');
    const $privateWeddingsCarouselTexts = $privateWeddingsCarousel.find('.private-weddings-carousel-texts');
    const $privateWeddingsCarouselItemText = $privateWeddingsCarouselTexts.find('.private-weddings-carousel-item-text');
    const $privateWeddingsCarouselLast = $privateWeddingsCarousel.find('.private-weddings-carousel-last');
    const $privateWeddingsCarouselNext = $privateWeddingsCarousel.find('.private-weddings-carousel-next');
    let position = 0;
    let carouselInterval = null;
    let windowHeight = 0;

    const updateCarousel = (pos) => {
      if (pos >= $privateWeddingsCarouselItemImage.length) {
        position = 0;
      } else if (pos < 0) {
        position = $privateWeddingsCarouselItemImage.length - 1;
      } else {
        position = pos;
      }

      const $currentItemImage = $privateWeddingsCarouselItemImage.eq(position);
      const $currentItemText = $privateWeddingsCarouselItemText.eq(position);
      const imageSrc = $currentItemImage.css('background-image')
        .replace('url(', '')
        .replace(')', '')
        .replace(/\"/gi, "");

      if (imageSrc.length) {
        $('<img/>').attr('src', imageSrc).on('load', function() {
          if (this.width >= this.height) {
            $privateWeddingsCarousel.addClass('item-landscape');
          } else {
            $privateWeddingsCarousel.removeClass('item-landscape');
          }
        }); 
      }

      $privateWeddingsCarouselItemImage.removeClass('active');
      $privateWeddingsCarouselItemText.removeClass('active');
      $currentItemImage.addClass('active');
      $currentItemText.addClass('active');
    }

    const restartCarouselInterval = () => {
      if (carouselInterval) {
        clearInterval(carouselInterval)
      }

      carouselInterval = setInterval(() => {
        updateCarousel(position + 1);
      }, 4000)
    }

    const updateScrollAnimated = (scrollTop) => {
      $privateWeddingsScrollAnimated.each((index) => {
        const $currentScrollAnimated = $privateWeddingsScrollAnimated.eq(index);
        const currentScrollAnimatedOffsetTop = $currentScrollAnimated.offset().top;
        const currentScrollAnimatedHeight = $currentScrollAnimated.innerHeight();
        let currentScrollAnimatedFix = parseInt(
          $currentScrollAnimated.attr('scroll-animated'),
          10,
        );

        if (currentScrollAnimatedFix < 0) {
          return;
        }

        let visiblePosition = currentScrollAnimatedOffsetTop;

        visiblePosition = visiblePosition + (currentScrollAnimatedHeight * (
          currentScrollAnimatedFix / 100
        ));
        visiblePosition = visiblePosition - windowHeight;

        if (scrollTop >= visiblePosition) {
          $currentScrollAnimated.addClass('visible');
        } else {
          $currentScrollAnimated.removeClass('visible');
        }
      });
    }

    $document.ready(() => {
      windowHeight = $window.innerHeight();

      updateCarousel(position);
      restartCarouselInterval();

      $privateWeddingsCarouselLast.click(() => {
        updateCarousel(position - 1);

        restartCarouselInterval()
      });

      $privateWeddingsCarouselNext.click(() => {
        updateCarousel(position + 1);

        restartCarouselInterval()
      });
    });

    $window.scroll(() => {
      if (hasPrivateWeddingsScrollAnimated) {
        const scrollTop = $window.scrollTop();

        updateScrollAnimated(scrollTop);
      }
    });

    $window.resize(() => {
      windowHeight = $window.innerHeight();

      if (hasPrivateWeddingsScrollAnimated) {
        const scrollTop = $window.scrollTop();

        updateScrollAnimated(scrollTop);
      }
    });
  }

})();

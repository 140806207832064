import $ from 'jquery';

;(() => {

  const $window = $(window);
  const hasWindow = $window && $window.length;
  const $document = $(document);
  const hasDocument = $document && $document.length;
  const $galleryTemplate = $('.page-template-gallery');
  const hasGalleryTemplate = $galleryTemplate && $galleryTemplate.length;

  if (hasWindow && hasDocument && hasGalleryTemplate) {
    const $galleryScrollAnimated = $galleryTemplate.find('[scroll-animated]');
    const hasGalleryScrollAnimated = $galleryScrollAnimated && $galleryScrollAnimated.length;

    let windowHeight = 0;

    const setupGalleryViewMore = () => {
      const $clientsGallery = $galleryTemplate.find('.clients-gallery');
      const $clientsGalleryHide = $galleryTemplate.find('.clients-gallery-hide');
      const $galleryViewMore = $galleryTemplate.find('.gallery-view-more');

      if (
        $clientsGallery && $clientsGallery.length &&
        $clientsGalleryHide && $clientsGalleryHide.length &&
        $galleryViewMore && $galleryViewMore.length
      ) {
        $galleryViewMore.click(onClickGalleryViewMore);
      }

      if (!$clientsGalleryHide.find('.client-item').length) {
        $galleryViewMore.addClass('hide');
      }

      onResizeWindow();
    }

    const updateGalleryScrollAnimated = scrollTop => {
      const $updatedGalleryScrollAnimated = $galleryTemplate.find('[scroll-animated]');

      $updatedGalleryScrollAnimated.each(index => {
        const $currentGalleryScrollAnimated = $updatedGalleryScrollAnimated.eq(index);
        const currentGalleryScrollAnimatedOffsetTop = $currentGalleryScrollAnimated.offset().top;
        const currentGalleryScrollAnimatedHeight = $currentGalleryScrollAnimated.innerHeight();
        let currentGalleryScrollAnimatedFixHeight = parseInt(
          $currentGalleryScrollAnimated.attr('scroll-animated'),
          10,
        );

        if (currentGalleryScrollAnimatedFixHeight < 0) {
          return;
        }

        let visiblePosition = currentGalleryScrollAnimatedOffsetTop;
        visiblePosition = visiblePosition + (currentGalleryScrollAnimatedHeight * (
          currentGalleryScrollAnimatedFixHeight / 100
        ));
        visiblePosition = visiblePosition - windowHeight;

        if (scrollTop >= visiblePosition) {
          $currentGalleryScrollAnimated.addClass('visible');
        } else {
          $currentGalleryScrollAnimated.removeClass('visible');
        }
      });
    }

    const onScrollWindow = () => {
      if (hasGalleryScrollAnimated) {
        const scrollTop = $window.scrollTop();

        updateGalleryScrollAnimated(scrollTop);
      }
    }

    const onResizeWindow = () => {
      windowHeight = $window.innerHeight();
      onScrollWindow();
    }

    const onDocumentReady = () => {
      onResizeWindow();

      const $galleryViewMore = $galleryTemplate.find('.gallery-view-more');

      if ($galleryViewMore && $galleryViewMore.length) {
        setupGalleryViewMore();
      }

      $window.resize(onResizeWindow);
      $window.scroll(onScrollWindow);
    }

    $document.ready(onDocumentReady);
  }

})();

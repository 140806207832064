import $ from 'jquery';

;(() => {

  const $window = $(window);
  const hasWindow = $window && $window.length;
  const $document = $(document);
  const hasDocument = $document && $document.length;
  const $shopTemplate = $('.page-template-shop');
  const hasShopTemplate = $shopTemplate && $shopTemplate.length;

  if (hasWindow && hasDocument && hasShopTemplate) {
    const paginationAmount = 6;
    const $shopScrollAnimated = $shopTemplate.find('[scroll-animated]');
    const hasShopScrollAnimated = $shopScrollAnimated && $shopScrollAnimated.length;
    const $closeDropdown = $shopTemplate.find('.email-capture-drop-down .close-drop-down');
    const $hasCloseDropdown = $closeDropdown && $closeDropdown.length;
    const $emailCaptureDropdown = $shopTemplate.find('.email-capture-drop-down');
    const hasEmailCaptureDropdown = $emailCaptureDropdown && $emailCaptureDropdown.length;

    let windowHeight = 0;

    const onClickCloseDropdown = () => {
      if (hasEmailCaptureDropdown) {
        $emailCaptureDropdown.removeClass('show');
      }
    }

    const setupShopViewMore = () => {
      const $shopList = $shopTemplate.find('.shop-list');
      const $shopListHide = $shopTemplate.find('.shop-list-hide');
      const $shopViewMore = $shopTemplate.find('.shop-view-more');

      if (
        $shopList && $shopList.length &&
        $shopListHide && $shopListHide.length &&
        $shopViewMore && $shopViewMore.length
      ) {
        $shopViewMore.click(onClickShopViewMore);
      }

      if (!$shopListHide.find('.shop-item').length) {
        $shopViewMore.addClass('hide');
      }

      onResizeWindow();
    }

    const updateShopScrollAnimated = scrollTop => {
      const $updatedShopScrollAnimated = $shopTemplate.find('[scroll-animated]');

      $updatedShopScrollAnimated.each(index => {
        const $currentShopScrollAnimated = $updatedShopScrollAnimated.eq(index);
        const currentShopScrollAnimatedOffsetTop = $currentShopScrollAnimated.offset().top;
        const currentShopScrollAnimatedHeight = $currentShopScrollAnimated.innerHeight();
        let currentShopScrollAnimatedFixHeight = parseInt(
          $currentShopScrollAnimated.attr('scroll-animated'),
          10,
        );

        if (currentShopScrollAnimatedFixHeight < 0) {
          return;
        }

        let visiblePosition = currentShopScrollAnimatedOffsetTop;
        visiblePosition = visiblePosition + (currentShopScrollAnimatedHeight * (
          currentShopScrollAnimatedFixHeight / 100
        ));
        visiblePosition = visiblePosition - windowHeight;

        if (scrollTop >= visiblePosition) {
          $currentShopScrollAnimated.addClass('visible');
        } else {
          $currentShopScrollAnimated.removeClass('visible');
        }
      });
    }

    const onClickShopViewMore = event => {
      const $shopListHide = $shopTemplate.find('.shop-list-hide');
      const $shopViewMore = $(event.currentTarget);

      for (let i = 0; i < paginationAmount; i++) {
        const $shopList = $shopTemplate.find('.shop-list');
        const $currentShopItemHide = $shopListHide.find('.shop-item').eq(i);

        if ($currentShopItemHide && $currentShopItemHide.length) {
          const $currentShopItemHideClone = $currentShopItemHide.clone();

          $currentShopItemHideClone.find('.shop-item-title').removeClass('visible');
          $currentShopItemHideClone.find('.shop-item-link').removeClass('visible');
          $currentShopItemHideClone.find('.shop-item-image').removeClass('visible');

          $shopList.append($currentShopItemHideClone);
          $currentShopItemHide.remove();
        }

        if (!$shopListHide.find('.shop-item').length) {
          $shopViewMore.addClass('hide');

          onScrollWindow();

          return;
        }
      }

      onScrollWindow();
    }

    const onScrollWindow = () => {
      if (hasShopScrollAnimated) {
        const scrollTop = $window.scrollTop();

        updateShopScrollAnimated(scrollTop);
      }
    }

    const onResizeWindow = () => {
      windowHeight = $window.innerHeight();
      onScrollWindow();
    }

    const onDocumentReady = () => {
      onResizeWindow();

      const $shopViewMore = $shopTemplate.find('.shop-view-more');

      if ($shopViewMore && $shopViewMore.length) {
        setupShopViewMore();
      }

      if ($hasCloseDropdown) {
        $closeDropdown.click(onClickCloseDropdown);
      }

      if (hasEmailCaptureDropdown) {
        setTimeout(() => { $emailCaptureDropdown.addClass('show'); }, 1000);
      }

      $window.resize(onResizeWindow);
      $window.scroll(onScrollWindow);
    }

    $document.ready(onDocumentReady);
  }

})();

import $ from 'jquery';

;(() => {

  const $window = $(window);
  const hasWindow = $window && $window.length;
  const $document = $(document);
  const hasDocument = $document && $document.length;
  const $header = $('#header');
  const hasHeader = $header && $header.length;

  if (hasWindow && hasDocument && hasHeader) {
    const onClickHeader = () => {
      const $body = $('body');

      if ($body.hasClass('show-nav')) {
        $body.removeClass('show-nav');
      } else {
        $body.addClass('show-nav');
      }
    }

    const onDocumentReady = () => {
      $header.click(onClickHeader);
    }

    $document.ready(onDocumentReady);

    $window.scroll(() => {
      const scroll = $window.scrollTop();

      if (scroll <= 75 && $header.hasClass('hide-logo')) {
        $header.removeClass('hide-logo');

        if ($('.email-capture-drop-down').length) {
          $('.email-capture-drop-down').removeClass('hide-logo');
        }
      }

      if (scroll > 75 && !$header.hasClass('hide-logo')) {
        $header.addClass('hide-logo')

        if ($('.email-capture-drop-down').length) {
          console.log(2);
          $('.email-capture-drop-down').addClass('hide-logo');
        }
      }

      if ($('.email-capture-drop-down').length && !$('.email-capture-drop-down').hasClass('scrolled')) {
        $('.email-capture-drop-down').addClass('scrolled');
      }
  });
  }

})();

import $ from 'jquery';

;(() => {

  const $window = $(window);
  const hasWindow = $window && $window.length;
  const $document = $(document);
  const hasDocument = $document && $document.length;
  const $collabsTemplate = $('.page-template-collabs');
  const hasCollabsTemplate = $collabsTemplate && $collabsTemplate.length;

  if (hasWindow && hasDocument && hasCollabsTemplate) {
    const $collabsScrollAnimated = $collabsTemplate.find('[scroll-animated]');
    const hasCollabsScrollAnimated = $collabsScrollAnimated && $collabsScrollAnimated.length;

    let windowHeight = 0;

    const updateCollabsScrollAnimated = scrollTop => {
      $collabsScrollAnimated.each(index => {
        const $currentCollabsScrollAnimated = $collabsScrollAnimated.eq(index);
        const currentCollabsScrollAnimatedOffsetTop = $currentCollabsScrollAnimated.offset().top;
        const currentCollabsScrollAnimatedHeight = $currentCollabsScrollAnimated.innerHeight();
        let currentCollabsScrollAnimatedFixHeight = parseInt(
          $currentCollabsScrollAnimated.attr('scroll-animated'),
          10,
        );

        if (currentCollabsScrollAnimatedFixHeight < 0) {
          return;
        }

        let visiblePosition = currentCollabsScrollAnimatedOffsetTop;
        visiblePosition = visiblePosition + (currentCollabsScrollAnimatedHeight * (
          currentCollabsScrollAnimatedFixHeight / 100
        ));
        visiblePosition = visiblePosition - windowHeight;

        if (scrollTop >= visiblePosition) {
          $currentCollabsScrollAnimated.addClass('visible');
        } else {
          $currentCollabsScrollAnimated.removeClass('visible');
        }
      });
    }

    const onScrollWindow = () => {
      if (hasCollabsScrollAnimated) {
        const scrollTop = $window.scrollTop();

        updateCollabsScrollAnimated(scrollTop);
      }
    }

    const onResizeWindow = () => {
      windowHeight = $window.innerHeight();
      onScrollWindow();
    }

    const onDocumentReady = () => {
      onResizeWindow();

      $window.resize(onResizeWindow);
      $window.scroll(onScrollWindow);
    }

    $document.ready(onDocumentReady);
  }

})();

import $ from 'jquery';

;(() => {

  const $window = $(window);
  const hasWindow = $window && $window.length;
  const $document = $(document);
  const hasDocument = $document && $document.length;
  const $homeTemplate = $('.page-template-home');
  const hasHomeTemplate = $homeTemplate && $homeTemplate.length;

  if (hasWindow && hasDocument && hasHomeTemplate) {
    const $homeSlideshow = $homeTemplate.find('.home-slideshow');
    const hasHomeSlideshow = $homeSlideshow && $homeSlideshow.length;
    const $homeCarousel = $homeTemplate.find('.home-carousel');
    const hasHomeCarousel = $homeCarousel && $homeCarousel.length;
    const $homeScrollAnimated = $homeTemplate.find('[scroll-animated]');
    const hasHomeScrollAnimated = $homeScrollAnimated && $homeScrollAnimated.length;

    let homeSlideshowAmount = 0;
    let currentHomeSlideshowIndex = 0;
    let homeCarouselAmount = 0;
    let currentHomeCarouselIndex = 0;
    let windowHeight = 0;
    let homeSlideshowInterval = null;
    let homeCarouselInterval = null;
    let homeIntervalTime = 4 * 1000;

    const setupHomeSlideshow = () => {
      if (hasHomeSlideshow) {
        const $slideshowItem = $homeSlideshow.find('.slideshow-item');
        const $startHome = $homeTemplate.find('#start-home');
        const hasStartHome = $startHome && $startHome.length;
        const intervalTimeOnReady = parseInt($homeSlideshow.attr('interval-time'), 10);

        $homeSlideshow.addClass('fade-in');

        if ($slideshowItem && $slideshowItem.length) {
          homeSlideshowAmount = $slideshowItem.length;
          $slideshowItem.click(onClickStartHome);
        }

        if (hasStartHome) {
          const $closeDropdown = $homeTemplate.find('.email-capture-drop-down .close-drop-down');

          $startHome.addClass('fade-in');
          $startHome.click(onClickStartHome);

          if ($closeDropdown && $closeDropdown.length) {
            $closeDropdown.click(onClickCloseDropdown);
          }
        }

        if (intervalTimeOnReady && intervalTimeOnReady > 0) {
          homeIntervalTime = intervalTimeOnReady;
        }

        if (homeSlideshowAmount > 1) {
          setTimeout(() => { resetHomeSlideshowInterval(); }, 1000);
        }
      }
    }

    const setupHomeCarousel = () => {
      const $scrollToNext = $homeCarousel.find('.scroll-to-next');
      const $homeCarouselBox = $homeCarousel.find('.home-carousel-box');
      const $carouselNavPrev = $homeCarousel.find('.carousel-nav-prev');
      const $carouselNavNext = $homeCarousel.find('.carousel-nav-next');

      if ($scrollToNext && $scrollToNext.length) {
        $scrollToNext.click(handleScrollToNext);
      }

      if ($homeCarouselBox && $homeCarouselBox.length) {
        $homeCarouselBox.eq(0).addClass('visible');
        homeCarouselAmount = $homeCarouselBox.length;
        $homeCarouselBox.click(handleScrollToNext);
      }

      if ($carouselNavPrev && $carouselNavPrev.length) {
        $carouselNavPrev.click(onClickCarouselNav);
      }

      if ($carouselNavNext && $carouselNavNext.length) {
        $carouselNavNext.click(onClickCarouselNav);
      }

      if (homeCarouselAmount > 1) {
        resetHomeCarouselInterval();
      }
    }

    const updateHomeSlideshow = () => {
      const $slideshowItem = $homeSlideshow.find('.slideshow-item');
      const $currentSlideshowItem = $slideshowItem.eq(currentHomeSlideshowIndex);
      const currentLogoColor = $currentSlideshowItem.attr('logo-color');

      if (currentHomeSlideshowIndex < 0) {
        currentHomeSlideshowIndex = homeSlideshowAmount;
      }

      if (currentHomeSlideshowIndex >= homeSlideshowAmount) {
        currentHomeSlideshowIndex = 0;
      }

      if (currentLogoColor && currentLogoColor.length) {
        $homeTemplate.removeClass('black-logo white-logo');
        $homeTemplate.addClass(`${currentLogoColor}-logo`);
      }

      $slideshowItem.removeClass('active');
      $currentSlideshowItem.addClass('active');
    }

    const updateHomeCarousel = () => {
      if (hasHomeCarousel) {
        const $homeCarouselBox = $homeCarousel.find('.home-carousel-box');

        if (currentHomeCarouselIndex < 0) {
          currentHomeCarouselIndex = homeCarouselAmount - 1;
        }

        if (currentHomeCarouselIndex >= homeCarouselAmount) {
          currentHomeCarouselIndex = 0;
        }

        $homeCarouselBox.removeClass('visible');
        $homeCarouselBox.eq(currentHomeCarouselIndex).addClass('visible');
      }
    }

    const updateHomeScrollAnimated = scrollTop => {
      $homeScrollAnimated.each(index => {
        const $currentHomeScrollAnimated = $homeScrollAnimated.eq(index);
        const currentHomeScrollAnimatedOffsetTop = $currentHomeScrollAnimated.offset().top;
        const currentHomeScrollAnimatedHeight = $currentHomeScrollAnimated.innerHeight();
        let currentHomeScrollAnimatedFixHeight = parseInt(
          $currentHomeScrollAnimated.attr('scroll-animated'),
          10,
        );

        if (currentHomeScrollAnimatedFixHeight < 0) {
          return;
        }

        let visiblePosition = currentHomeScrollAnimatedOffsetTop;
        visiblePosition = visiblePosition + (currentHomeScrollAnimatedHeight * (
          currentHomeScrollAnimatedFixHeight / 100
        ));
        visiblePosition = visiblePosition - windowHeight;

        if (scrollTop >= visiblePosition) {
          $currentHomeScrollAnimated.addClass('visible');
        } else {
          $currentHomeScrollAnimated.removeClass('visible');
        }
      });
    }

    const resetHomeSlideshowInterval = () => {
      if (homeSlideshowInterval) {
        clearInterval(homeSlideshowInterval);
      }

      if (homeSlideshowAmount > 1) {
        homeSlideshowInterval = setInterval(handleHomeSlideshowInterval, homeIntervalTime);
      }
    }

    const clearHomeSlideshowInterval = () => {
      if (homeSlideshowInterval) {
        clearInterval(homeSlideshowInterval);
      }
    }

    const clearHomeCarouselInterval = () => {
      if (homeCarouselInterval) {
        clearInterval(homeCarouselInterval);
      }
    }

    const resetHomeCarouselInterval = () => {
      if (homeCarouselInterval) {
        clearInterval(homeCarouselInterval);
      }

      if (homeCarouselAmount > 1) {
        homeCarouselInterval = setInterval(handleHomeCarouselInterval, homeIntervalTime);
      }
    }

    const handleHomeSlideshowInterval = () => {
      currentHomeSlideshowIndex = currentHomeSlideshowIndex + 1;

      if (hasHomeSlideshow) {
        updateHomeSlideshow();
      }
    }

    const handleHomeCarouselInterval = () => {
      currentHomeCarouselIndex = currentHomeCarouselIndex + 1;

      updateHomeCarousel();
    }

    const handleScrollToNext = () => {
      const $homeClientListsGallery = $homeTemplate.find('.client-lists-gallery');
      const hasHomeClientListsGallery = $homeClientListsGallery && $homeClientListsGallery.length;
      let headerHeight = 0

      if (hasHomeClientListsGallery) {
        const $header = $homeTemplate.find('#header');
        const homeClientListsGalleryOffsetTop = $homeClientListsGallery.offset().top;

        if ($header && $header.length) {
          headerHeight = $header.innerHeight();
        }

        resetHomeCarouselInterval();
        $('html, body').animate({
          scrollTop: homeClientListsGalleryOffsetTop - headerHeight,
        }, 600);
      }
    }

    const onClickStartHome = () => {
      const $startHome = $homeTemplate.find('#start-home');
      const hasStartHome = $startHome && $startHome.length;
      const $header = $('#header');
      const hasHeader = $header && $header.length;
      const $emailCaptureDropdown = $homeTemplate.find('.email-capture-drop-down');
      const hasEmailCaptureDropdown = $emailCaptureDropdown && $emailCaptureDropdown.length;
      const windowHeight = $window.innerHeight();
      const $carouselBoxesWrapper = $homeTemplate.find('.carousel-boxes-wrapper');
      let fixScroll = $carouselBoxesWrapper && $carouselBoxesWrapper.length
        ? $carouselBoxesWrapper.height()
        : 0;

      clearHomeSlideshowInterval();
      clearHomeCarouselInterval();

      if (hasStartHome) {
        $startHome.addClass('fade-out');
        setTimeout(() => { $startHome.addClass('hide'); }, 3000);
      }

      if (hasHomeSlideshow) {
        $homeSlideshow.addClass('fade-out');
        setTimeout(() => { $homeSlideshow.addClass('hide'); }, 3000);
      }

      if (hasHomeCarousel) {
        $homeCarousel.addClass('fade-in');
        setTimeout(() => { resetHomeCarouselInterval(); }, 2000);
      }

      if (hasHeader) {
        $header.addClass('fade-in');
      }

      $homeTemplate.addClass('home-started');

      setTimeout(() => {
        // handleScrollToNext();

        if (hasEmailCaptureDropdown) {
          setTimeout(() => { $emailCaptureDropdown.addClass('show') }, 800);
        }
      }, 1000);
    }

    const onClickCarouselNav = event => {
      const $currentTarget = $(event.currentTarget);
      const hasCurrentTarget = $currentTarget && $currentTarget.length;

      if (hasCurrentTarget) {
        if ($currentTarget.hasClass('carousel-nav-prev')) {
          currentHomeCarouselIndex = currentHomeCarouselIndex - 1;
        } else if ($currentTarget.hasClass('carousel-nav-next')) {
          currentHomeCarouselIndex = currentHomeCarouselIndex + 1;
        }

        resetHomeCarouselInterval();
        updateHomeCarousel();
      }
    }

    const onClickCloseDropdown = () => {
      const $emailCaptureDropdown = $homeTemplate.find('.email-capture-drop-down');
      const hasEmailCaptureDropdown = $emailCaptureDropdown && $emailCaptureDropdown.length;

      if (hasEmailCaptureDropdown) {
        $emailCaptureDropdown.removeClass('show');
      }
    }

    const onScrollWindow = () => {
      if (hasHomeScrollAnimated) {
        const scrollTop = $window.scrollTop();

        updateHomeScrollAnimated(scrollTop);
      }
    }

    const onResizeWindow = () => {
      windowHeight = $window.innerHeight();
      onScrollWindow();
    }

    const onDocumentReady = () => {
      onResizeWindow();
      windowHeight = $window.innerHeight();

      if (hasHomeCarousel) {
        setupHomeCarousel();
      }

      if (hasHomeSlideshow) {
        setupHomeSlideshow();
      } else {
        const $header = $homeTemplate.find('#header');
        const hasHeader = $header && $header.length;
        const $signupSuccessDropDown = $homeTemplate.find('.signup-success');
        const hasSignupSuccessDropDown = $signupSuccessDropDown && $signupSuccessDropDown.length;
        
        if (hasHeader) {
          $header.addClass('fade-in-fast');
        }

        if (hasHomeCarousel) {
          $homeCarousel.addClass('fade-in-fast');
        }

        if (hasSignupSuccessDropDown) {
          setTimeout(() => { $signupSuccessDropDown.addClass('show'); }, 300);

          const $closeDropdown = $homeTemplate.find('.email-capture-drop-down .close-drop-down');

          if ($closeDropdown && $closeDropdown.length) {
            $closeDropdown.click(onClickCloseDropdown);
          }
        }

        $homeTemplate.addClass('home-started');
      }

      $window.resize(onResizeWindow);
      $window.scroll(onScrollWindow);
    }

    $document.ready(onDocumentReady);
  }

})();

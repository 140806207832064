import $ from 'jquery';

;(() => {

  const $window = $(window);
  const hasWindow = $window && $window.length;
  const $document = $(document);
  const hasDocument = $document && $document.length;
  const $pressTemplate = $('.page-template-press');
  const hasPressTemplate = $pressTemplate && $pressTemplate.length;

  if (hasWindow && hasDocument && hasPressTemplate) {
    const $pressScrollAnimated = $pressTemplate.find('[scroll-animated]');
    const hasPressScrollAnimated = $pressScrollAnimated && $pressScrollAnimated.length;

    let windowHeight = 0;

    const updatePressScrollAnimated = scrollTop => {
      $pressScrollAnimated.each(index => {
        const $currentHomeScrollAnimated = $pressScrollAnimated.eq(index);
        const currentHomeScrollAnimatedOffsetTop = $currentHomeScrollAnimated.offset().top;
        const currentHomeScrollAnimatedHeight = $currentHomeScrollAnimated.innerHeight();
        let currentHomeScrollAnimatedFixHeight = parseInt(
          $currentHomeScrollAnimated.attr('scroll-animated'),
          10,
        );

        if (currentHomeScrollAnimatedFixHeight < 0) {
          return;
        }

        let visiblePosition = currentHomeScrollAnimatedOffsetTop;
        visiblePosition = visiblePosition + (currentHomeScrollAnimatedHeight * (
          currentHomeScrollAnimatedFixHeight / 100
        ));
        visiblePosition = visiblePosition - windowHeight;

        if (scrollTop >= visiblePosition) {
          $currentHomeScrollAnimated.addClass('visible');
        } else {
          $currentHomeScrollAnimated.removeClass('visible');
        }
      });
    }

    const onScrollWindow = () => {
      if (hasPressScrollAnimated) {
        const scrollTop = $window.scrollTop();

        updatePressScrollAnimated(scrollTop);
      }
    }

    const onResizeWindow = () => {
      windowHeight = $window.innerHeight();
      onScrollWindow();
    }

    const onDocumentReady = () => {
      onResizeWindow();

      windowHeight = $window.innerHeight();

      $window.resize(onResizeWindow);
      $window.scroll(onScrollWindow);
    }

    $document.ready(onDocumentReady);
  }

})();

import $ from 'jquery';

;(() => {

  const $window = $(window);
  const hasWindow = $window && $window.length;
  const $document = $(document);
  const hasDocument = $document && $document.length;
  const $bridalTemplate = $('.page-template-bridal');
  const hasBridalTemplate = $bridalTemplate && $bridalTemplate.length;
  
  // const hasBridalCarousel = $bridalCarousel && $bridalCarousel.length;
  // const $slideshowWrapper = $bridalCarousel.find('.carousel-wrapper');
  // const hasCarouselWrapper = $slideshowWrapper && $slideshowWrapper.length;

  if (hasWindow && hasDocument && hasBridalTemplate) {
    const $bridalCarousel = $bridalTemplate.find('.bridal-carousel');
    const hasBridalCarousel = $bridalCarousel && $bridalCarousel.length;
    const $bridalScrollAnimated = $bridalTemplate.find('[scroll-animated]');
    const hasBridalScrollAnimated = $bridalScrollAnimated && $bridalScrollAnimated.length;

    let bridalCarouselAmount = 0;
    let currentBridalCarouselIndex = 0;
    let bridalCarouselInterval = null;

    let windowHeight = 0;
    let bridalIntervalTime = 4 * 1000;

    const setupBridalCarousel = () => {
      if (hasBridalCarousel) {
        const $scrollToNext = $bridalCarousel.find('.scroll-to-next');
        const $carouselImage = $bridalCarousel.find('.carousel-image');
        const $carouselNavPrev = $bridalCarousel.find('.carousel-nav-prev');
        const $carouselNavNext = $bridalCarousel.find('.carousel-nav-next');


        if ($scrollToNext && $scrollToNext.length) {
          $scrollToNext.click(handleBridalScrollToNext);
        }

        if ($carouselImage && $carouselImage.length) {
          const $fisrtCarouselImage = $bridalCarousel.find('.carousel-image').eq(0);

          $carouselImage.click(handleBridalScrollToNext);
          $fisrtCarouselImage.addClass('visible');
          bridalCarouselAmount = $carouselImage.length;
        }

        if ($carouselNavPrev && $carouselNavPrev.length) {
          $carouselNavPrev.click(onClickBridalCarouselNav)
        }

        if ($carouselNavNext && $carouselNavNext.length) {
          $carouselNavNext.click(onClickBridalCarouselNav)
        }

        if (bridalCarouselAmount > 1) {
          resetBridalCarouselInterval();
        }
      }
    }

    const updateBridalCarousel = () => {
      if (hasBridalCarousel) {
        if (currentBridalCarouselIndex < 0) {
          currentBridalCarouselIndex = bridalCarouselAmount - 1;
        }

        if (currentBridalCarouselIndex >= bridalCarouselAmount) {
          currentBridalCarouselIndex = 0;
        }

        const $carouselImage = $bridalCarousel.find('.carousel-image');
        const $currentCarouselImage = $carouselImage.eq(currentBridalCarouselIndex);

        $carouselImage.removeClass('visible');
        $currentCarouselImage.addClass('visible');
      }
    }

    const updateBridalScrollAnimated = scrollTop => {
      $bridalScrollAnimated.each(index => {
        const $currentBridalScrollAnimated = $bridalScrollAnimated.eq(index);
        const currentBridalScrollAnimatedOffsetTop = $currentBridalScrollAnimated.offset().top;
        const currentBridalScrollAnimatedHeight = $currentBridalScrollAnimated.innerHeight();
        let currentBridalScrollAnimatedFixHeight = parseInt(
          $currentBridalScrollAnimated.attr('scroll-animated'),
          10,
        );

        if (currentBridalScrollAnimatedFixHeight < 0) {
          return;
        }

        let visiblePosition = currentBridalScrollAnimatedOffsetTop;
        visiblePosition = visiblePosition + (currentBridalScrollAnimatedHeight * (
          currentBridalScrollAnimatedFixHeight / 100
        ));
        visiblePosition = visiblePosition - windowHeight;

        if (scrollTop >= visiblePosition) {
          $currentBridalScrollAnimated.addClass('visible');
        } else {
          $currentBridalScrollAnimated.removeClass('visible');
        }
      });
    }

    const resetBridalCarouselInterval = () => {
      if (bridalCarouselInterval) {
        clearInterval(bridalCarouselInterval);
      }

      if (bridalCarouselAmount > 1) {
        bridalCarouselInterval = setInterval(handleBridalCarouselInterval, bridalIntervalTime);
      }
    }

    const handleBridalCarouselInterval = () => {
      currentBridalCarouselIndex = currentBridalCarouselIndex + 1;
      
      updateBridalCarousel();
    }

    const handleBridalScrollToNext = () => {
      let $target = $('#footer');
      const $clientListsGallery = $bridalTemplate.find('.client-lists-gallery');

      if ($clientListsGallery && $clientListsGallery.length) {
        $target = $clientListsGallery;
      }
      
      if ($target && $target.length) {
        const targetOffsetTop = $target.offset().top;

        resetBridalCarouselInterval();
        $('html, body').animate({ scrollTop: targetOffsetTop }, 600);
      }
    }

    const onClickBridalCarouselNav = event => {
      const $currentTarget = $(event.currentTarget);

      if ($currentTarget && $currentTarget.length) {
        if ($currentTarget.hasClass('carousel-nav-prev')) {
          currentBridalCarouselIndex = currentBridalCarouselIndex - 1;
        } else if ($currentTarget.hasClass('carousel-nav-next')) {
          currentBridalCarouselIndex = currentBridalCarouselIndex + 1;
        }

        resetBridalCarouselInterval();
        updateBridalCarousel();
      }
    }

    const onScrollWindow = () => {
      if (hasBridalScrollAnimated) {
        const scrollTop = $window.scrollTop();

        updateBridalScrollAnimated(scrollTop);
      }
    }

    const onResizeWindow = () => {
      windowHeight = $window.innerHeight();
      onScrollWindow();
    }

    const onDocumentReady = () => {
      windowHeight = $window.innerHeight();
      onResizeWindow();

      if (hasBridalCarousel) {
        setupBridalCarousel();
      }

      $window.resize(onResizeWindow);
      $window.scroll(onScrollWindow);
    }

    $document.ready(onDocumentReady);
  }

})();

import $ from 'jquery';

;(() => {

  const $window = $(window);
  const hasWindow = $window && $window.length;
  const $document = $(document);
  const hasDocument = $document && $document.length;
  const $aboutTemplate = $('.page-template-about');
  const hasCollabsTemplate = $aboutTemplate && $aboutTemplate.length;

  if (hasWindow && hasDocument && hasCollabsTemplate) {
    const $aboutScrollAnimated = $aboutTemplate.find('[scroll-animated]');
    const hasCollabsScrollAnimated = $aboutScrollAnimated && $aboutScrollAnimated.length;
    const $aboutImage = $aboutTemplate.find('.about-image');
    const hasAboutImage = $aboutImage && $aboutImage.length;

    let windowHeight = 0;
    let headerHeight = 0;
    let footerOffsetTop = 0;

    const updateCollabsScrollAnimated = scrollTop => {
      $aboutScrollAnimated.each(index => {
        const $currentCollabsScrollAnimated = $aboutScrollAnimated.eq(index);
        let currentCollabsScrollAnimatedOffsetTop = $currentCollabsScrollAnimated.offset().top;
        const currentCollabsScrollAnimatedHeight = $currentCollabsScrollAnimated.innerHeight();
        let currentCollabsScrollAnimatedFixHeight = parseInt(
          $currentCollabsScrollAnimated.attr('scroll-animated'),
          10,
        );
        let visiblePosition = 0;

        if (currentCollabsScrollAnimatedFixHeight < 0) {
          return;
        }

        if ($currentCollabsScrollAnimated.hasClass('about-text-cont')) {
          const $aboutTextContParagraph = $currentCollabsScrollAnimated.find('p');

          if ($aboutTextContParagraph && $aboutTextContParagraph.length) {
            $currentCollabsScrollAnimated.addClass('visible');

            $aboutTextContParagraph.each(jIndex => {
              const $currentAboutTextContParagraph = $aboutTextContParagraph.eq(jIndex);
              const currentAboutTextContParagraphHeight = $currentAboutTextContParagraph.innerHeight();

              currentCollabsScrollAnimatedHeight

              currentCollabsScrollAnimatedOffsetTop = $currentAboutTextContParagraph.offset().top;
              visiblePosition = currentCollabsScrollAnimatedOffsetTop;
              visiblePosition = visiblePosition + (currentAboutTextContParagraphHeight * (
                currentCollabsScrollAnimatedFixHeight / 100
              ));
              visiblePosition = visiblePosition - windowHeight;

              if (scrollTop >= visiblePosition) {
                $currentAboutTextContParagraph.addClass('visible');
              } else {
                $currentAboutTextContParagraph.removeClass('visible');
              }
            });
          }
        } else {
          visiblePosition = currentCollabsScrollAnimatedOffsetTop;
          visiblePosition = visiblePosition + (currentCollabsScrollAnimatedHeight * (
            currentCollabsScrollAnimatedFixHeight / 100
          ));
          visiblePosition = visiblePosition - windowHeight;

          if (scrollTop >= visiblePosition) {
            $currentCollabsScrollAnimated.addClass('visible');
          } else {
            $currentCollabsScrollAnimated.removeClass('visible');
          }
        }
      });
    }

    const updateAboutImageOnScroll = scrollTop => {
      if (!hasAboutImage) {
        return;
      }

      if (scrollTop >= headerHeight) {
        if (!$aboutImage.hasClass('fixed')) {
          $aboutImage.addClass('fixed');
        }

        if (scrollTop >= footerOffsetTop - windowHeight) {
          const aboutImageTranslateY = (footerOffsetTop - windowHeight) - scrollTop;
          $aboutImage.css('transform', `translateY(${aboutImageTranslateY}px)`);
        } else {
          $aboutImage.css('transform', '');
        }
      } else {
        if ($aboutImage.hasClass('fixed')) {
          $aboutImage.removeClass('fixed');
        }

        $aboutImage.css('transform', '');
      }
    }

    const onScrollWindow = () => {
      if (hasCollabsScrollAnimated) {
        const scrollTop = $window.scrollTop();

        updateCollabsScrollAnimated(scrollTop);
        updateAboutImageOnScroll(scrollTop);
      }
    }

    const onResizeWindow = () => {
      windowHeight = $window.innerHeight();
      headerHeight = $aboutTemplate.find('#header').innerHeight();
      footerOffsetTop = $aboutTemplate.find('#footer').offset().top;

      onScrollWindow();
    }

    const onDocumentReady = () => {
      onResizeWindow();

      $window.resize(onResizeWindow);
      $window.scroll(onScrollWindow);
    }

    $document.ready(onDocumentReady);
  }

})();

import $ from 'jquery';

import './js/pages/home';
import './js/pages/bridal';
import './js/pages/single-client';
import './js/pages/shop';
import './js/pages/gallery';
import './js/pages/press';
import './js/pages/collabs';
import './js/pages/about';
import './js/pages/private-clients-weddings';

import './js/components/header';
import './js/libs/_libs';

import './scss/style.scss';
